.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 110;
  transition: opacity 0.3s ease;
}

.modal.open {
  display: flex;
}

.modal-content {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 80%;
  max-width: 400px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

h2 {
  margin-top: 0;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-top: 10px;
}

input,
textarea {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 18px;
  resize: none;
  background-color: #f5f9fc;
}
input::placeholder,
textarea::placeholder {
  color: #c6cacc;
}

.buttonBtn {
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 18px;
  transition: background-color 1s ease;
}

button:hover {
  background-color: #0056b3;
}

.options {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 18px;
  text-decoration: none;
  background-color: #f5f9fc;
}

@media (max-width: 992px) {
  .modal-content {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 80%;
    max-width: 400px;
    position: relative;
    max-height: 80vh;
    overflow: auto;
  }
}
