/* JobDetails.css */

.job-details-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 20px;
}

.job-details-container h2 {
  color: #333;
}

.job-details-container p {
  margin-bottom: 10px;
}

.job-details-container ul {
  list-style-type: none;
  padding: 0;
}

.job-details-container li {
  margin-bottom: 5px;
}
.job-details-container a {
  text-decoration: underline;
  color: blue;
}
