/* :root {
} */

.active {
  border-bottom: 2px solid #e5b80b;
}

/* Add more styles to enhance readability */
p a {
  text-decoration: underline;
  /* Underline links for clarity */
  color: #0074cc;
  /* Change link color */
}

/* Style headings for better hierarchy */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  p {
    font-size: 14px;
    line-height: 1.4;
  }
}

.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: green;
  z-index: 100;
}

/* footer */

.footer {
  background-color: #012348;
}

.footer-heading-text {
  color: #ffff;
  cursor: pointer;
}

.footer-sub-heading {
  color: #859eaf;
}

.footer-heading-text:hover,
.footer-sub-heading:hover {
  color: #ff5a00;
}

section.flex.flex-col.contact {
  gap: 15px;
}
.m-4alter {
  margin: 1rem 1rem 0rem 0rem;
}
.footer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10%;
  grid-gap: 20px;
}
.footer-mid {
  width: 25vw;
}

/* footer end */
.contact-us-title {
  margin-bottom: -40px;
  z-index: 1;
  position: relative;
  background: aliceblue;
  background-image: linear-gradient(100deg, #ff5a00 0%, #fda61a 100%);
  box-shadow: 0 0 0 0 #ffffff;
  height: 20vh;
}

.contact-us-title > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2vh;
}

.contact-us-title h3,
.connect {
  margin-bottom: 0px;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}

.contact-us-title .title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us-title .bg-white {
  display: flex;
  justify-content: center;
  height: 5vh;
  align-items: center;
  border-radius: 50px;
  padding: 15px 25px;
  transition: all 0.3s ease;
  cursor: pointer;
  color: #ff5a00;
}

.contact-us-title .bg-white:hover {
  padding: 15px 35px;
}

.banner {
  background-image: url("../public/assets/Images/4.jpeg");
  height: 100vh;
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  padding-left: 10%;
  align-items: center;
}
.banner-parking {
  background-image: url("../public/assets/Images/Group\ 1000002692.png");
  height: 100vh;
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  padding-left: 10%;
  align-items: center;
}
.banner-driver {
  background-image: url("../public/assets/Images/image\ 1.png");
  height: 100vh;
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  padding-left: 10%;
  align-items: center;
}
.banner-garage {
  background-image: url("../public/assets/Images/Group\ 1000002699.png");
  height: 100vh;
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  padding-left: 10%;
  align-items: center;
}
.banner-wash {
  background-image: url("../public/assets/Images/Group\ 1000002691.png");
  height: 100vh;
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  padding-left: 10%;
  align-items: center;
}
@media (max-width: 786px) {
  .banner-parking{
    background-image: url("../public/assets/Images/Group\ 10000026921111.png");
  }
  .banner-garage{
    background-image: url("../public/assets/Images/Group\ 10000026991111.png");
  }
  .banner-wash{
    background-image: url("../public/assets/Images/Group\ 10000026911111.png");
  }
}

.banner-parking-1 {
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../public/assets/Images/man-valet-holding-key-full-shot.jpg");
  height: 100vh;
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  padding-left: 10%;
  align-items: center;
}
/* .banner-driver {
  background-image: url("../public/assets/Images/image\ 1.png");
  height: 100vh;
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  padding-left: 10%;
  align-items: center;
} */
.banner-garage-1 {
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../public/assets/Images/male-mechanics-working-car-shop-together.jpg");
  height: 100vh;
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  padding-left: 10%;
  align-items: center;
}
.banner-wash-1 {
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../public/assets/Images/unsplash__DSom3ySpow.png");
  height: 100vh;
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  padding-left: 10%;
  align-items: center;
}
@media (max-width: 786px) {
  .banner-wash-1{
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../public/assets/Images/unsplash__DSom3ySpow111.png");
  }
  .banner-parking-1{
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../public/assets/Images/man-valet-holding-key-full-shot111.jpg");
  }
  .banner-garage-1{
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../public/assets/Images/male-mechanics-working-car.jpg");
  }
}

.banner div {
  padding: 10px;
}
.main-logo{
  filter: brightness(0) saturate(100%) brightness(0) saturate(100%) invert(69%) sepia(22%) saturate(4351%) hue-rotate(11deg) brightness(108%) contrast(91%);;
}
.banner-parking div {
  padding: 10px;
}
.banner-garage div {
  padding: 10px;
}
.banner-wash div {
  padding: 10px;
}
/* New Css */

.logoText {
  font-size: 25px;
  font-family: "slick";
  border-bottom: 2px solid #e5b80b;
}

/* slider css */
.blurbg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 101;
  opacity: 0.6;
}

.full .slick-next {
  right: 100px !important;
  z-index: 100;
}

.full .slick-prev {
  left: 60px !important;
  z-index: 100;
}

.full .slick-prev:before {
  content: url("../public/assets/Images/left-arrow.svg") !important;
}

.full .slick-next:before {
  content: url("../public/assets/Images/right-arrow.svg") !important;
}

.full .slick-prev:before,
.slick-next:before {
  display: inline-block;
  width: 30px !important;
  height: 30px !important;
}

.full .slick-dots {
  bottom: 50px !important;
}

.full .slick-dots li {
  width: 4rem !important;
  height: 2px;
}

.full .slick-dots li button:before {
  content: "" !important;
  background: #fff !important;
  width: 40px !important;
  height: 2.16px !important;
  border-radius: 999px;
}

/* Get in touch button */
li button:hover {
  color: white;
  background-color: #e5b80b;
  transition: 0.3s;
}

nav ul li {
  cursor: pointer;
}

/* slider */
.slider {
  position: fixed;
  background: white;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}
.slider .drawar {
  position: relative;
  z-index: 20;
  background: white;
  height: 100vh;
  width: 0vw;
  transition: 0.5s;
  margin-right: -150px;
  overflow-y: auto;
}

.show {
  width: 70vw !important;
  margin-right: 0 !important;
}

.navOption {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navOption ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.navOption ul li {
  font-size: 15px;
  font-weight: 300;
  margin: 10px;
}

hr {
  border: 1px solid #e5dcdc;
  margin: 10px;
}

.copyright {
  text-align: center;
  margin: 2rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #646161;
}

.hideContent {
  display: none;
}
.socialMedia {
  border: 1px solid #e5dcdc;
  border-radius: 8px;
  margin: 1rem;
}
.socialMedia_content {
  display: flex;
  justify-content: center;
}
.socialMedia a {
  margin: 1rem 0.5rem;
  border-radius: 4px;
}
.socialMedia_content a,
.socialMedia_content div {
  border-radius: 4px;
}
.socialMedia_content .insta {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #cac273 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
.socialMedia_content .facebook,
.socialMedia_content .linkde {
  background: blue;
}
.followUs {
  position: absolute;
  margin: -7px 0px 0px 30px;
  background: white;
  padding: 0px 6px;
}
/* Modal end */

/* about */
/* .about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-sec .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.content .header {
  font-size: 25px;
  font-weight: 500;
}
.content p {
  margin-top: 1rem;
}

.about-grad {
  background-image: linear-gradient(45deg, #da8989, transparent);
  border-radius: 10px;
}
.thought-grad {
  background-image: linear-gradient(-45deg, #e4e05f, transparent);
  border-radius: 10px;
} */
/* about end */

/* services */
.service {
  display: flex;
  justify-content: center;
  gap: 28px;
  /* flex-wrap: wrap; Allow items to wrap to the next line */

  /* @media (min-width: 768px) {
    justify-content: space-around;
  } */
}

/* services end */

/* tiles */
.tile {
  /* margin: 1rem 4.5rem; */
  border: 1px solid #e2b938;
  width: 400px;
  height: 400px;
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  transition: 0.5s ease;
}
.tile:hover {
  /* border: 2px solid #e2b938;
  box-shadow: 0px 0px 5px 5px #ccc; */
  scale: 1.05;
}

.tile .heading {
  color: black;
  font-size: 20px;
  font-weight: 600;
}
.tile .readme {
  bottom: 2rem;
  position: absolute;
  color: #3939c6;
}
/* tiles end */
/* career tab */
.career-tab {
  width: 100%;
  display: flex !important;
  justify-content: center;
}
.career .slick-slider {
  margin: 0px 100px !important;
}
/* career tab end */

.servicdeDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.servicdeDetails .title {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 1px;
}
.servicdeDetails .description {
  width: 50vw;
  font-style: italic;
  letter-spacing: 1px;
}

/* responsiveness */
@media (max-width: 992px) {
  .full .slick-prev,
  .full .slick-next {
    display: none !important;
  }
  .footer-container {
    grid-template-columns: 1fr;
  }
  .footer-mid {
    width: 50vw;
  }
  .modal-content {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 80%;
    max-width: 400px;
    position: relative;
    max-height: 80vh;
    overflow: auto;
  }
  .about-sec {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .about-sec .content {
    width: 80vw;
  }
  .service {
    flex-direction: column;
  }
  .tile {
    width: 100%;
    height: 400px;
    margin-bottom: 2rem;
  }
  .servicdeDetails .description {
    width: 85vw;
  }
}
@media (min-width: 992px) {
  .slider {
    display: none;
  }
}
.enquiry-pill{
  background: linear-gradient(to left, #292929, #4d4c4c);
  position: fixed;
  height: 200px;
  width: 50px;
  top: 40%;
  right: 0;
  margin-right: 16px;
  writing-mode: vertical-rl; /* Set vertical writing mode */
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  line-height: 1.5;
  border-radius: 35px;
  cursor: pointer;
  transition: 0.8s ease;
}
.enquiry-pill:hover{
  scale: 1.1;
}
.enquiry-pill-text{
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.enquiry-pill-icon{
  height: 24px;
}
.enquiry-pill-icon-div{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  font-weight: 600;
  background-color: #fff;
  border-radius: 50%;
}
.about-title{
  text-align: center;
  align-self: center;
  font-size: 25px;
  font-weight: 600;
}
.about-card-parking-div{
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}
.about-card{
  padding: 24px;
  width: 350px;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  
  gap: 10px;
  transition: 0.5s ease;
}
.about-head-title{
  font-size: 25px;
  font-weight: 600;
}
.about-desc{
  font-size: 16px;
  text-align: justify;
}
.about-head{
  padding: 0px 15px;
  font-size: 22px;
  font-weight: 500;
  align-self: center;
  text-align: center;
  margin-top: 15px;
}
.about-card:hover{
  scale: 1.05;
}
.button-getin-div{
  display: flex;
  justify-content: center;
}