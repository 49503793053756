.footer-main-div {
  background-color: #000435;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
}
.footer-div {
  background-color: #000435;
  margin-top: 86px;
}
.footer-logo-div {
  width: 40%;
  display: flex;
  gap: 20px;
}
.footer-contact-div {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.footer-links-div {
  width: 9%;
}
.footer-email {
  display: flex;
  gap: 10px;
  font-style: italic;
  margin-top: 10px;
  flex-wrap: wrap;
  color: #fff;
}
.footer-loc {
  display: flex;
  margin-top: 15px;
  gap: 1px;
  font-style: italic;
  color: #fff;
}
.footer-social-div {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 40px;
  flex-wrap: wrap;
}
.grid{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #fff;
}
.footer-contact-logo{
    filter: brightness(0) saturate(100%) invert(97%) sepia(2%) saturate(3%) hue-rotate(234deg) brightness(116%) contrast(100%);;
}
/* Add this CSS for mobile responsiveness */
@media only screen and (max-width: 768px) {
  .footer-main-div {
    flex-direction: column;
    gap: 20px;
  }

  .footer-logo-div,
  .footer-links-div,
  .footer-contact-div {
    width: 100%;
  }
  .footer-logo-div{
    display: block;
  }

  .footer-social-div {
    margin-top: 20px; /* Adjust spacing for social media icons */
  }

  .grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-loc{
    flex-wrap: nowrap;
  }
}
