.new-blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 36px;
}

.blogs-text {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 84px; /* Adjust the margin as needed */
  color: #2C2C2C;
}

.new-blog-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.new-blog-card {
  border: 1px solid #dcdcdc;
  height: 350px;
  width: 350px; /* Set a fixed width for each card or adjust as needed */
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s ease;
}
.new-blog-card:hover{
    scale: 1.05;
}
.new-blog-date {
  color: #fff;
  margin-top: -20px;
  font-size: 12px;
  margin-left: 5px;
}
.new-blog-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
  margin-top: 16px;
}
.new-blog-content{
    font-size: 14px;
    margin-top: 16px;
}