/* BlogComponent.css */

.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.blog-card {
  width: 350px;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;

  &:hover {
    transform: scale(1.05);
  }
}

.blog-image {
  width: 100%;
  object-fit: cover;
  padding: 10px;
}

.blog-content {
  padding: 15px;
}

.blog-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: black;
}

.blog-text {
  color: #555;
}
.blog-date-text{
  color: #5a99f1;
  font-size: 14px;
  margin-bottom: 10px;
}
.read-more{
  color: #5a99f1;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}